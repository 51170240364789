import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    public router: Router,
    public storageService: StorageService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin();
  }
  checkLogin() {
    const data = this.storageService.get('user');
    const url = this.router.url.split('/')[1];
    if (data && data.user.isLoggedIn) {
      switch (url) {
        case '':
          return this.router.navigate(['/new/campaign/new/basic']), false;
        case 'auth':
          return this.router.navigate(['/new/campaign/new/basic']), false;
        case 'campaign':
          return false;
      }
    } else {
      return true;
    }
  }
}

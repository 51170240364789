import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LazyModuleLoaderModel } from './directive/lazy.model';

@Component({
  selector: 'app-module-loader',
  templateUrl: './module-loader.component.html',
  styleUrls: ['./module-loader.component.scss']
})
export class ModuleLoaderComponent implements OnInit, OnChanges {

  @Input() load;
  @Input() moduleId;
  @Input() selector;
  @Input() inputs;
  @Input() outputs;

  lazyModuleConfig: LazyModuleLoaderModel;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.inputs?.currentValue) {
      this.loadLazyModule();
      return;
    } else if ((!changes?.load?.currentValue) || (changes?.load?.currentValue?.toString() === changes?.load?.previousValue?.toString()) || (changes?.load?.currentValue === changes?.load?.previousValue)) {
      return;
    }
    this.loadLazyModule();
  }


  loadLazyModule() {
    if (!this.load) {
      return;
    }
    this.lazyModuleConfig = {
      loader: this.getModulePath(),
    }
  }

  getModulePath() {
    switch (true) {

      case this.moduleId === 'update-tab':
        return () => import('./../update/update.module');

      case this.moduleId === 'comment-card':
        return () => import('./../comment-card/comment-card.module');

      case this.moduleId === 'fundraiser-thankyou':
        return () => import('./../fundraiser-thankyou/fundraiser-thankyou.module');

      case this.moduleId === 'payment-module':
        return () => import('./../payment/payment.module');

      case this.moduleId === 'order-reco-card':
        return () => import('../order-reco-card/order-reco-card.module');

      case this.moduleId === 'captcha-input':
        return () => import('../captcha-input/captcha-input.module');

      case this.moduleId === 'intl-input':
        return () => import('../intl-input/intl-input.module');

      case this.moduleId === 'common-header':
        return () => import('../common-header/common-header.module');

      default:
        break;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inrFormat'
})
export class InrFormatPipe implements PipeTransform {

  /**
   * args: currency. Ex: inr,usd
   */
  transform(value: any, args?: any): any {
    if (value) {
      if (args && args.toLowerCase() === 'inr') {
        return parseInt(value, 10).toLocaleString('en-IN').split('.')[0];
      } else {
        return parseInt(value, 10).toLocaleString().split('.')[0];
      }
    }
  }
}

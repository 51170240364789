import { Injectable } from '@angular/core';

declare let bulk_donation_hf_mini_AB;
declare let desktop_tip_AB;

@Injectable({
  providedIn: 'root'
})
export class ABService {

  bulk_donation_hf_mini_AB() {
    if (typeof bulk_donation_hf_mini_AB !== 'undefined') {
      return bulk_donation_hf_mini_AB;
    }
  }

  desktop_tip_AB() {
    if (typeof desktop_tip_AB !== 'undefined') {
      return desktop_tip_AB;
    }
  }

}

import { Injectable, NgZone } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';

import { IPaymentSipConfig } from 'src/app/core/payment-sip/payment-sip.model';
import { ICart } from '../../../common/payment/models/cart.model';
import { IOrder } from '../../../common/payment/models/order.model';
import { IUser } from '../../models';
import { Currency } from '../../models/currency-list';

import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { CookieService } from '../cookie/cookie.service';
import { EventsService } from '../events/events.service';
import { PusherService } from '../pusher/pusher.service';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import { StorageService } from '../storage/storage.service';
import { UtilService } from '../util/util.service';
import { VariablesService } from '../variables/variables.service';
import { ISipCause } from '../../models/sip-cause.model';
import { ABService } from '../ab/ab.service';
import { IFundraiser } from '../../models/fundraiser.model';

// Global Variables
declare const Stripe;
declare const Razorpay;
declare const Juspay;
declare const $;

@Injectable({
  providedIn: 'root'
})
export class PaymentV2Service {

  iterations;
  isOrderLoading;
  stripeSwitch;
  stripeFailed = false;
  options;
  sipDonors;
  selectedButtonId = new BehaviorSubject<string>('');
  noCauseSelected = false;
  paymentConfig = new BehaviorSubject<IPaymentSipConfig>(null);
  userFormSubmit = new BehaviorSubject<any>(null);
  formBtnClick = new BehaviorSubject<any>(null);
  oneTapSubmit = new BehaviorSubject<{ mode: string, card_name_fill: string, mode_value: string, customer_id: string }>(null);
  paymentOptions = new ReplaySubject<any>(1);
  pledgeBtn = new Subject<string>();
  captchaResponse;
  otpVerified;
  upiQrCode = { generated: false, status: false, message: '' };
  paytmQrCode = { generated: false, status: false, message: '' };
  isDonorTypeEventSent = false;
  isContributeInitEventHit = false;
  slabs;

  constructor(
    private vars: VariablesService,
    private util: UtilService,
    private api: ApiService,
    private events: EventsService,
    private cookie: CookieService,
    private pusher: PusherService,
    private storage: StorageService,
    private scriptLoader: ScriptLoaderService,
    private zone: NgZone,
    private dialog: MatDialog,
    private auth: AuthService,
    public sheet: MatBottomSheet,
    private AB: ABService
  ) { }

  /** We will check if user last order payment mode is exist
   * in options enabled for payment */
  setUserLastOrder(user, options) {
    return new Promise((resolve, reject) => {
      try {
        const lastOrder = user.lastorder;
        if (lastOrder && Object.keys(options).includes(lastOrder.payment_mode)) {
          this.paymentConfig.next({ lastOrder: lastOrder });
          resolve(lastOrder);
        } else {
          reject();
        }
      } catch (error) {
        reject(false);
      }
    });
  }

  /** Create User Cart */
  async createCart(data): Promise<{ fundraiser: IFundraiser, cart: ICart }> {
    return new Promise((resolve, reject) => {
      try {
        this.isOrderLoading = true;
        const user: IUser = data.user || this.vars.userData.getValue();
        const client = this.vars.clientLocationData.getValue();
        const payload = {
          campaign_id: data.campaign_id,
          donated_amount: data.amount,
          currency: data.currency,
          donor_name: user.full_name,
          donor_phone: user?.phone_1 || this.vars?.userPhoneNumber || 1111111111,
          donor_email: user.email,
          is_anonymous: data.is_anonymous ? '1' : '0',
          device: this.vars.deviceType,
          donor_country: client.country_name,
          donor_city: client?.city_name,
          donor_extension: user?.extension || this.vars?.userPhoneExt || '',
          donor_pincode: user.pincode,
          accessToken: this.otpVerified || null,
          'g-recaptcha-response': this.captchaResponse || null,
          pledge_more: data.pledge_more || null,
          sip_cause: data.sip_cause || null,
          wallet_to_autopay: +data.wallet_to_autopay || null,
          split: (data.sip_cause || data.sip_story) ? false : null,
          skipmin: data.skipmin || null,
          transaction_type: data.transaction_type || null,
          wallet_recharge: data.wallet_recharge || null,
          recurring: data.recurring || null,
          tip_amount: data.tip_amount || null,
          multicause: data?.multicause || null,
          cause_amount: 1
        };

        const utmParams = this.cookie.getDecodedCookies('k_utm');
        if (utmParams) {
          payload['utm_campaign'] = utmParams.utm_campaign || '';
          payload['utm_medium'] = utmParams.utm_medium || '';
          payload['utm_source'] = utmParams.utm_source || '';
          payload['utm_term'] = utmParams.utm_term || '';
          payload['utm_content'] = utmParams.utm_content || '';
          payload['utm_placement'] = utmParams.utm_placement || '';
        }

        const cartPayload = this.util.removeEmptyFromObject(payload);

        const url = environment.APP.API_URLS.POST_AMOUNT_DETAILS(data.campaign_id);
        this.api.post(url, cartPayload).subscribe({
          next: async (resp) => {
            const cart_data = {
              ...resp.data,
              sip_cause_str: this.util.jsonToString(data?.sip_cause),
              timestamp: new Date().getTime()
            }
            if (!this.vars.isVariableLogin) {
              this.storage.checkFromSession('sip_cart', cart_data);
            }
            this.isOrderLoading = false;
            if (cart_data.hasOwnProperty('token')) {
              this.vars.loginMethod = 'cart';
              const newUser = await this.auth.userLogin(cart_data);
            }
            if (!this.isContributeInitEventHit) {
              await this.eventForInitiatePayment(11, cart_data.campaign, cart_data.cart, data);
            }
            await this.eventAfterCreatingCart(cart_data.campaign, cart_data.cart, data);
            this.isOrderLoading = false;
            this.captchaResponse = null;
            resolve({ fundraiser: cart_data.campaign, cart: cart_data.cart });
            this.captchaResponse = null;
          },
          error: (err) => {
            this.isOrderLoading = false;
            this.captchaResponse = null;
            reject(true);
          }
        });
      } catch (error) {
        reject(false);
      }
    });
  }

  createOrder(data) {
    return new Promise((resolve, reject) => {
      try {
        // if (data?.mode === 'UPIRECURRING' && this.vwo.payu_AB()) {
        //   data['payment_gateway'] = 'payu_recurring';
        // }
        this.options = data.options;
        this.isOrderLoading = true;
        const payload = {
          campaign_id: data?.cart?.campaign_id || data.fundraiser.id,
          cart_id: data.cart.id,
          mode: data.mode,
          gateway: data.options.gateway,
          mode_value: data.mode_value,
          bin: data.mode === 'CARD' && !data.customer_id && data.card_number_fill ? data.card_number_fill.substr(0, 6) : null,
          page_type: this.vars.pageName || this.vars.campaignType,
          ab_testname: this.events.ab.abtestname || '',
          ab_value: this.events.ab.abvalue || '',
          vpa: data.VPA || '',
          payment_gateway: data.payment_gateway || '',
          is_indian: data.cart.indian || '',
          donor_pincode: data.donor_pincode,
          month_interval: data.month_interval || null,
          subscription_id: data.subscription_id || null,
          sip_cause: data.sip_cause || null,
          transaction_type: data.transaction_type || null,
          support_campaign_id: data?.fundraiser?.support_campaign?.id || null,
          wallet_to_autopay: +data.wallet_to_autopay || null,
          tenure: +data.tenure || null,
          frequency_term: data.frequency_term || null,
          frequency: +data.frequency || null,
          ngo_sip: +data.ngo_sip || null,
          multicause: data?.multicause || null,
          cause_amount: 1
        };

        data.stripeSwitch = false; // removed stripe switch for all sip payment

        if (data.stripeSwitch && data.cart.currency === 'INR' && !this.stripeFailed && !data.stripeIndiaOnly) {
          payload.payment_gateway = 'stripe_recurring';
        }

        const utmParams = this.cookie.getDecodedCookies('k_utm');
        if (utmParams) {
          payload['utm_campaign'] = utmParams.utm_campaign || '';
          payload['utm_medium'] = utmParams.utm_medium || '';
          payload['utm_source'] = utmParams.utm_source || '';
          payload['utm_term'] = utmParams.utm_term || '';
          payload['utm_content'] = utmParams.utm_content || '';
          payload['utm_placement'] = utmParams.utm_placement || '';
        }

        const orderPayload = this.util.removeEmptyFromObject(payload);

        this.api.post(environment.APP.API_URLS.PLACE_ORDER(data?.cart?.campaign_id || data.fundraiser.id), orderPayload).subscribe({
          next: async (orderRes) => {
            await this.eventAfterCreatingOrder(orderRes.data, data);
            if (orderRes.data.payment_mode.match('UPI|PAYTM')) {
              this.vars.currentOrderId = orderRes.data.order_id;
              this.pusher.sendOrderId = true;
              this.pusher.activateUPIPusher(orderRes.data);
            }
            this.storage.checkFromSession(orderRes.data.order_id, data.cart);
            if (orderRes.data.payment_gateway.match('stripe|stripeindia')) {
              this.iterations = 0;
              this.stripeCheck(orderRes, data);
            } else if (orderRes.data.payment_gateway.match('juspay') && orderRes.data.payment_mode === 'CARD') {
              this.juspayCheck(orderRes, data);
            } else {
              this.sendOrder(orderRes.data, data);
            }
          },
          error: (error) => {
            this.isOrderLoading = false;
            this.selectedButtonId.next('');
            reject(true);
          }
        });
      } catch (error) {
        reject(false);
      }
    });
  }

  /**Check stripe condition
   * if payment gateway is stripe or stripe india
   * token is required for payment
  */
  stripeCheck(orderRes, data) {
    const timeout = setTimeout(() => {
      this.scriptLoader.load('stripe').then(() => {
        // console.log(this.iterations);
        if (typeof Stripe !== 'undefined') {
          this.stripePayment(orderRes, data);
        }
        if (typeof Stripe === 'undefined' && this.iterations === 3) {
          this.isOrderLoading = false;
          this.util.openSnackBar('Please try again by clicking on donation button', 'error');
          clearTimeout(timeout);
        }
        if (typeof Stripe === 'undefined' && this.iterations <= 2) {
          this.stripeCheck(orderRes, data);
          this.iterations++;
        }
      });
    }, this.iterations === 0 ? 0 : 3000);
  }
  /**Payment initiate using stripe */
  stripePayment(orderRes, data) {
    if (data.stripeSwitch && orderRes.data.iso_currency === 'INR') {
      this.stripeSwitchForRecurringDonations(orderRes, data);
    } else if (data.stripeSwitch && orderRes.data.iso_currency !== 'INR' && data.customer_id) {
      this.sendOrder(orderRes.data, Object.assign(data, { customer_id: data.customer_id }));
    } else if (!data.stripeSwitch && data.customer_id) {
      this.sendOrder(orderRes.data, Object.assign(data, { customer_id: data.customer_id }));
    } else if (!data.stripeSwitch && orderRes.data.iso_currency === 'INR') {
      this.stripeSwitchForOneTimeDonations(orderRes, data);
    } else {
      Stripe.setPublishableKey(this.options.setting.stripe.key);
      this.getStripeToken(orderRes, data);
    }
  }
  /** Get Stripe Token and return */
  async getStripeToken(orderRes, data) {
    try {
      const token = await this.createStripeCardToken(data);
      this.sendOrder(orderRes.data, Object.assign(data, { token: token, save_card: data.save_card }));
    } catch (error) {
      this.util.openSnackBar(error.message, 'error');
      this.isOrderLoading = false;
    }
  }
  /** Switch to stipe and create token for one time donations */
  async stripeSwitchForOneTimeDonations(orderRes, data) {
    try {
      Stripe.setPublishableKey(this.options.setting.stripe.key_us);
      const tokenUS = await this.createStripeCardToken(data);
      Stripe.setPublishableKey(this.options.setting.stripe.key);
      const tokenInr = await this.createStripeCardToken(data);
      this.sendOrder(orderRes.data, Object.assign(data, { token: tokenInr, save_card: data.save_card, token1: tokenUS }));
    } catch (error) {
      this.util.openSnackBar(error.message, 'error');
      this.isOrderLoading = false;
    }
  }
  /** Recuring donation switch on the basis of customer_id/us_token/fresh donation */
  stripeSwitchForRecurringDonations(orderRes, data) {
    if (data.customer_id) {
      if (this.vars.stripeUsToken && !this.stripeFailed && !data.stripeIndiaOnly) {
        this.sendOrder(orderRes.data, Object.assign(data, { token: this.vars.stripeUsToken, save_card: data.save_card }));
      } else {
        this.sendOrder(orderRes.data, Object.assign(data, { customer_id: data.customer_id, save_card: data.save_card, token: null }));
      }
    } else {
      if (!this.stripeFailed && !data.stripeIndiaOnly) {
        Stripe.setPublishableKey(this.options.setting.stripe.key_us);
        this.getStripeToken(orderRes, data);
      } else {
        Stripe.setPublishableKey(this.options.setting.stripe.key);
        data.stripeIndiaOnly = false;
        this.getStripeToken(orderRes, data);
      }
    }
  }
  /**Create stripe token */
  createStripeCardToken(card) {
    return new Promise((resolve, reject) => {
      try {
        const stipePaylad = {
          number: card.card_number_fill,
          cvc: card.card_cvv_fill,
          exp_month: card.card_month_fill,
          exp_year: card.card_year_fill
        };
        Stripe.card.createToken(stipePaylad, (status, response) => response.error ? reject(response.error) : resolve(response.id));
      } catch (error) {
        reject(false);
      }
    });
  }
  /**Check juspay condition
   * if payment gateway is juspay token is required for payment usgin card
   * */
  juspayCheck(orderRes, data) {
    data['merchant_id'] = this.options.setting.juspay.key;
    this.scriptLoader.load('jquery').then(() => {
      this.scriptLoader.load('juspay').then(() => {
        this.sendOrder(orderRes.data, data);
      });
    });
  }

  /**Send the order to the for gateway respnse */
  sendOrder(order: IOrder, data?) {
    const payload = {
      order_id: order.order_id,
      token: data.token,
      token_1: data.token1,
      save_card: data.save_card,
      customer_id: data.customer_id,
      account_no: data.bank_account,
      ifsc_code: data.bank_ifsc,
      bank_code: data.bank_code,
      bank_name: data.bank_name || null,
      auth_mode: data.auth_mode,
      payment_method_change: data.payment_method_change || null
    };
    const user: IUser = this.vars.userData.getValue();
    // Thank you page params
    this.vars.tyParams = {
      ...this.util.actRoute.snapshot.queryParams,
      id: this.util.checkNestedObj(data, 'cart.campaign_id') || order.campaign_id,
      or: data.origin,
      dnr: user.aggdonationall && user.aggdonationall.funds ? 'rp' : 'fs',
      lpm: order.payment_gateway.match('stripe') ? 'strp' : 'nstrp',
      url: this.util.router.url.split('?')[0],
      donated_currency: order.iso_currency,
      donated_amount: order.donated_amount,
      tip_amount: order?.tip_amount || null,
      amt: data?.cart?.recurring ? data?.cart?.donated_amount || order.donated_amount : null,
      ct: data.ct,
      payment_v: 'v2',
      oi: order.order_id,
      last_pay_mode: data.mode,
      pledge_more: data.pledge_more || null,
      sip_cause: data.sip_cause || null,
      sip_story: data.sip_story ? 1 : null,
      tenure: +data.tenure || null,
      frequency_term: data.frequency_term || null,
      frequency: +data.frequency || null,
      sip_wallet: data?.sip_wallet || null,
      wallet_tenure: data?.wallet_tenure || null,
      plan: data?.plan || null,
    };
    if (data?.sip_cause) {
      this.vars.tyParams['sip_cause'] = this.util.jsonToString(data?.sip_cause);
    }
    if (this.util.actRoute.snapshot.queryParams.or) {
      this.vars.tyParams['u_or'] = this.util.actRoute.snapshot.queryParams.or;
    }
    if (this.vars.tyParams) {
      delete this.vars.tyParams.page;
      delete this.vars.tyParams.st;
      delete this.vars.tyParams.last_success_order_id;
      delete this.vars.tyParams.payment;
      delete this.vars.tyParams.method;
      delete this.vars.tyParams.method_type;
    }

    // Do not update tyParams object after this line
    this.vars.tyParams = this.util.removeEmptyFromObject(this.vars.tyParams);
    this.storage.addSessionData('payment_return_params', { k_params: JSON.stringify(this.vars.tyParams), ...this.vars.tyParams });
    const domain = this.util.domain_details.domain_url + environment.base_ref;
    // payload['return_url'] = domain + `payment-route?${new URLSearchParams(this.vars.tyParams).toString()}`;
    payload['return_url'] = domain + `payment-route?${this.util.objToUrlString(this.vars.tyParams)}`;

    this.api.post(environment.APP.API_URLS.SEND_ORDER, payload).subscribe(async (res) => {
      const sendOrderRes = res.data;
      if (sendOrderRes.charged && sendOrderRes.status === 'success') {
        this.goToThankYouPage(order);
        this.isOrderLoading = false;
      } else if (sendOrderRes.charged === false) {
        switch (true) {
          // If payment intiated using upi icon
          case sendOrderRes.UPI:
            if (!this.util.isMobile() && order.payment_mode_value === 'DESKTOPQR' && order.payment_mode === 'UPI') {
              this.upiQrCode = { status: res.data.params.upi.status, message: res.data.params.upi.message, generated: true };
              this.isOrderLoading = false;
              break;
            }
            if (!this.util.isMobile() && order.payment_mode_value === 'DESKTOPQR' && order.payment_mode === 'PAYTM') {
              this.paytmQrCode = { status: res.data.params.paytm.status, message: res.data.params.paytm.message, generated: true };
              this.isOrderLoading = false;
              break;
            }
            if (order.payment_gateway === 'phonepe_recurring' || order.payment_gateway === 'payu_recurring') {
              if (this.util.isMobile() && res.data.url) {
                this.util.nativeNavigation(res.data.url);
              } else {
                this.vars.upiTimer = true;
              }
              this.pusher.vpaTxnStatus({
                order_id: order.order_id,
                origin: this.vars.tyParams?.or
              });
              this.isOrderLoading = false;
              break;
            }
            this.util.nativeNavigation(sendOrderRes.url);
            this.vars.upiTimer = true;
            this.isOrderLoading = false;
            break;

          // if payment intiated by adding upi address
          case sendOrderRes.hasOwnProperty('pg_txn_id') && order.payment_mode_value === 'VPA':
            this.pusher.vpaTxnStatus({ order_id: order.order_id, origin: data.origin_vpa });
            this.vars.upiTimer = true;
            this.isOrderLoading = false;
            break;

          // if payment intiated inside paytm mini app
          case order.payment_mode === 'PAYTMMA':
            this.paymentInsidePaytmMiniApp(sendOrderRes, order);
            break;

          // if payment intiated using razor pay emandete
          case order.payment_mode_value === 'Emandate' && order.payment_gateway === 'razorpay_emandate':
            this.scriptLoader.load('razor_pay').then(() => this.startRazorPayment(order, sendOrderRes.params));
            break;

          // if payment intiated using razor pay payment gatway for card
          case order.payment_gateway === 'razorpay' && order.payment_mode === 'CARD':
            this.scriptLoader.load('razor_pay_card').then(() => this.razorpayCardPaymnt(order, sendOrderRes.params, data));
            break;

          case order.payment_gateway === 'razorpay_emandate' && order.payment_mode === 'CARD':
            this.scriptLoader.load('razor_pay_card').then(() => this.razorpayCardPaymnt(order, sendOrderRes.params, data));
            break;

          // if payment intiated using razor pay payment gatway for UPI
          case order.payment_gateway === 'razorpay_emandate' && order.payment_mode === 'UPIRECURRING':
            this.scriptLoader.load('razor_pay_card').then(() => this.razorpayUPIPaymnt(order, sendOrderRes.params, data));
            break;

          // if payment intiated using razor pay payment gatway for Netbanking
          case order.payment_gateway === 'razorpay_emandate' && order.payment_mode === 'Emandate':
            this.scriptLoader.load('razor_pay_card').then(() => this.razorpayCustomCheckoutPayment(order, sendOrderRes.params, data));
            break;

          default:
            this.submitToPaymentGateway(res, data, order);
            break;
        }
      }
    }, (error) => {
      this.isOrderLoading = false;
      // tslint:disable-next-line: max-line-length
      if (order.payment_gateway === 'stripe_recurring' && order.iso_currency === 'INR' && order.payment_mode === 'CARD' && data.stripeSwitch) {
        this.stripeFailed = true;
        this.createOrder(data);
      } else {
        this.stripeFailed = false;
      }
    });
  }
  /**Submit foem to gateway */
  submitToPaymentGateway(res, data, order) {
    // tslint:disable-next-line:prefer-const
    let sendOrderRes = res.data;
    if (sendOrderRes.params) {
      for (const item of Object.entries(sendOrderRes.params)) {
        if (data.hasOwnProperty(item[1])) {
          sendOrderRes.params[item[0]] = data[item[1].toString()];
        }
      }
    }
    Object.assign(sendOrderRes, { gateway: this.options.gateway, mode: data.mode, order: order });
    this.submitToPaymentGatewayPost(sendOrderRes);
  }
  /**Submit to the payment gateway */
  submitToPaymentGatewayPost(res) {
    const gatewayRespnse = res; // assuming that `res` holds the data return by JusPay API
    const url = gatewayRespnse.url;
    const method = gatewayRespnse.method;

    const frm = document.createElement('form');
    // frm.style.display = 'none'; // ensure that the form is hidden from the user
    frm.setAttribute('method', method);
    frm.setAttribute('action', url);
    frm.setAttribute('id', 'payment_form');
    frm.setAttribute('class', 'juspay_inline_form'); // Class for juspay payment gateway

    // if (method === 'POST') {
    if (gatewayRespnse.params) {
      const params = gatewayRespnse.params;
      for (const key of Object.keys(params)) {
        const value = params[key];
        const field = document.createElement('input');
        field.setAttribute('type', 'hidden');
        field.setAttribute('name', key);
        field.setAttribute('class', key);
        field.setAttribute('value', value);
        frm.appendChild(field);
      }
    }
    document.body.appendChild(frm);
    if (res.gateway === 'juspay' && res.mode === 'CARD') {
      console.log('Juspay submit setup');
      this.juspayFormSubmit(res.order);
      $('#payment_form').submit();
    }
    // form is now ready
    frm.submit();
    // $('#payment_form').submit();
  }

  /**Juspay form submit */
  juspayFormSubmit(order) {
    Juspay.Setup({
      payment_form: '#payment_form',
      success_handler: (status) => {
        // redirect to success page
        this.goToThankYouPage(order);
      },
      error_handler: (error_code, error_message, bank_error_code, bank_error_message, gateway_id) => {
        // redirect to failure page
      }
    });
  }

  /** Razorpay card payment intent */
  razorpayCardPaymnt(order: IOrder, sendOrderRes, cardDetails) {
    const razConfig = {
      key: this.options.setting.razorpay.key,
      amount: sendOrderRes?.amount || order.donated_amount,
      name: 'Ketto',
      image: `${environment.APP.IMAGE_DOMAIN}/images/logo-light-bg.svg`
    };
    const razorpay = new Razorpay(razConfig);
    const payload = {
      amount: sendOrderRes?.amount,
      currency: sendOrderRes?.currency?.toUpperCase() || null,
      email: sendOrderRes?.email || cardDetails?.user?.email,
      contact: sendOrderRes?.contact || cardDetails?.user?.phone_1,
      order_id: sendOrderRes?.recurring_order_id || sendOrderRes?.order_id,
      customer_id: sendOrderRes?.customer_id || null,
      method: 'card',
      'card[name]': order?.donor_first_name,
      'card[number]': cardDetails?.card_number_fill,
      'card[cvv]': cardDetails?.card_cvv_fill,
      'card[expiry_month]': cardDetails?.card_month_fill,
      'card[expiry_year]': cardDetails?.card_year_fill
    };
    if (cardDetails?.cart?.recurring) {
      payload['recurring'] = 1;
    }
    razorpay.createPayment(payload);
    razorpay.on('payment.success', (resp) => {
      this.zone.run(() => {
        Object.assign(this.vars.tyParams, resp);
        this.util.router.navigate(['/new/payment-route'], { queryParams: this.vars.tyParams });
        this.dialog.closeAll();
        this.sheet.dismiss();
      });
    });
    razorpay.on('payment.error', (resp) => {
      this.isOrderLoading = false;
      this.util.openSnackBar(resp?.error?.description, 'error');
      this.util.router.navigate(['/new/payment-route'], { queryParams: this.vars.tyParams });
    });
  }

  razorpayCustomCheckoutPayment(order: IOrder, sendOrderRes, cardDetails) {
    const razConfig = {
      key: this.options.setting.razorpay.key,
      image: `${environment.APP.IMAGE_DOMAIN}/images/logo-light-bg.svg`
    };
    const razorpay = new Razorpay(razConfig);
    const payload = {
      amount: sendOrderRes.amount,
      currency: sendOrderRes.currency?.toUpperCase() || null,
      email: cardDetails.cart.donor_email,
      contact: cardDetails.cart.donor_extension + cardDetails.cart.donor_phone,
      order_id: sendOrderRes.recurring_order_id,
      customer_id: sendOrderRes.customer_id,
      recurring: '1',
      method: '',
      notes: {
        order_id: order.order_id,
        camp_id: order.campaign_id
      }
    };

    if (order.payment_mode === 'CARD') {
      // payload.method = 'card';
      // payload['card'] = {
      //   name: order.donor_first_name,
      //   number: cardDetails.card_number_fill,
      //   cvv: cardDetails.card_cvv_fill,
      //   expiry_month: cardDetails.card_month_fill,
      //   expiry_year: cardDetails.card_year_fill
      // };
    } else if (order.payment_mode === 'Emandate') {
      const nameStr: string = order.donor_first_name;
      if (nameStr.length < 3) {
        order.donor_first_name += ' Ketto';
      }
      payload.method = 'emandate';
      payload['bank'] = cardDetails.bank_code;
      payload['auth_type'] = 'netbanking';
      payload['bank_account[name]'] = order.donor_first_name;
      payload['bank_account[account_number]'] = cardDetails?.bank_account;
      payload['bank_account[account_type]'] = 'savings';
      payload['bank_account[ifsc]'] = cardDetails?.bank_ifsc;
    } else if (order.payment_mode === 'UPI' && order.checksum) {
      // payload.method = 'upi';
      // payload['vpa'] = order.checksum;
    } else if (order.payment_mode === 'WALLET') {
      // payload.method = 'wallet';
      // payload['wallet'] = order.payment_mode_value?.toLowerCase();
    }

    razorpay.createPayment(payload);
    if (order.payment_mode !== 'UPI') {
      razorpay.on('payment.success', (resp) => {
        this.isOrderLoading = false;
        this.vars.isOrderLoading = false;
        Object.assign(this.vars.tyParams, resp);
        this.util.router.navigate(['/new/payment-route'], { queryParams: this.vars.tyParams });
      });
    }
    razorpay.on('payment.error', (error) => {
      this.razorpayFailedPayment(error?.error);
    });
  }

  razorpayFailedPayment(error) {
    this.isOrderLoading = false;
    this.vars.isOrderLoading = false;
    this.vars.tyParams = {
      ...this.vars.tyParams,
      razorpay_payment_id: error?.metadata?.payment_id || '',
      razorpay_order_id: error?.metadata?.order_id || '',
      source: error?.source || '',
      reason: error?.description || '',
      showError: 'false'
    }
    this.util.openSnackBar(error?.description, 'error');
    this.api.post(environment.APP.API_URLS.GATEWAY_RESPONSE(this.vars.tyParams.oi), this.vars.tyParams).subscribe({
      next: (success) => {
        console.log('success', success);
        this.util.router.navigate(['/new/payment-route'], { queryParams: this.vars.tyParams });
      },
      error: (err) => {
        this.paymentFailedEvent(err?.error);
      }
    });
  }

  paymentFailedEvent(error) {
    const pageEvent = {
      eventName: 'payment_response',
      event_type: 'payment',
      // event_type_id: this.vars.tyParams['oi'],
      page_name: 'payment-redirect-page',
      info_1: 'failed',
      info_2: this.vars.tyParams['oi'],
      info_3: JSON.stringify(this.vars.tyParams),
      info_4: error?.message || '',
    };
    this.events.sendSystemEvent(pageEvent).subscribe(_ => _);
  }

  /**Start Razor payment intent */
  startRazorPayment(order, sendOrderRes) {
    const options = {
      key: this.options.setting.razorpay.key,
      order_id: sendOrderRes.recurring_order_id,
      customer_id: sendOrderRes.customer_id,
      recurring: '1',
      modal: {
        ondismiss: () => this.zone.run(() => this.isOrderLoading = false)
      },
      handler: (response) => {
        this.zone.run(() => {
          const qParams = { ...response, ...this.vars.tyParams };
          this.util.router.navigate(['/new/payment-route'], { queryParams: qParams });
        });
      },
      notes: {
        order_id: order.order_id,
        camp_id: order.campaign_id
      },
      theme: {
        color: '#01bfbd'
      }
    };
    const rzp1 = new Razorpay(options);
    rzp1.open();
  }


  /** Razorpay UPI payment VPA */
  razorpayUPIPaymnt(order: IOrder, sendOrderRes, data) {
    const options = {
      key: this.options?.setting?.razorpay?.key
    };
    const razorpay = new Razorpay(options);

    const payload = {
      amount: sendOrderRes?.amount,
      order_id: sendOrderRes?.recurring_order_id,
      customer_id: sendOrderRes?.customer_id,
      currency: sendOrderRes?.currency?.toUpperCase(),
      email: data?.user?.email,
      contact: data?.user?.phone_1,
      recurring: '1',
      method: 'upi',
      vpa: data?.VPA,
      notes: {
        order_id: order?.order_id,
        camp_id: order?.campaign_id
      }
    };

    const btn = document.createElement('button');
    btn.style.display = 'none';
    btn.innerHTML = 'razorpay_upi';
    btn.setAttribute('id', 'razorpay_upi');
    document.body.appendChild(btn);

    btn.addEventListener('click', () => {
      razorpay.createPayment(payload);
    });

    this.scriptLoader.load('jquery').then(() => {
      $('#razorpay_upi').click();
    });

    razorpay.on('payment.success', (resp) => {
      this.zone.run(() => {
        Object.assign(this.vars.tyParams, resp);
        this.isOrderLoading = false;
        this.util.router.navigate(['/new/payment-route'], { queryParams: this.vars.tyParams });
      });
    });
    razorpay.on('payment.error', (resp) => {
      this.isOrderLoading = false;
      this.util.openSnackBar(resp?.error?.description, 'error');
      this.util.router.navigate(['/new/payment-route'], { queryParams: this.vars.tyParams });
    });
  }


  paymentInsidePaytmMiniApp(sendOrderRes, order) {
    const requestObject = {
      'amount': sendOrderRes.params.TXN_AMOUNT.toString(),
      'orderId': order.order_id,
      'txnToken': sendOrderRes.params.TXN_TOKEN,
      'mid': sendOrderRes.params.MID,
    };
    this.isPaytmReady(() => {
      (<any>window).JSBridge.call('paytmPayment', requestObject, (result) => {
        console.log(result);
        this.zone.run(() => {
          const paytmPaymentRes = result && result.data ? JSON.parse(result.data) : result;
          if (paytmPaymentRes.STATUS === 'TXN_SUCCESS') {
            this.dialog.closeAll();
            this.sheet.dismiss();
            this.util.router.navigate(['/new/payment-route'], { queryParams: this.vars.tyParams });
          } else {
            this.isOrderLoading = false;
            this.util.openSnackBar('Please try again', 'error');
          }
        });
      });
    });
  }
  /**Paytm auth login */
  paytmAuthFetch() {
    const clientId = environment.production ? 'merchant-ketto-prod' : 'merchant-ketto-uat';
    return new Promise((resolve, reject) => {
      try {
        this.isPaytmReady(() => {
          ((<any>window).JSBridge).call('paytmFetchAuthCode', { clientId: clientId }, (result) => {
            if (result.hasOwnProperty('error')) {
              reject(result.error);
            } else {
              const qparam = `?code=${result.data.authId}`;
              this.api.get(environment.APP.API_URLS.PAYTM_LOGIN + qparam).subscribe((res) => resolve(res), (er) => reject(er));
            }
          });
        });
      } catch (error) {
        reject(false);
      }
    });
  }
  /**check if paytm apis are ready */
  isPaytmReady(callback) {
    if ((<any>window).JSBridge) {
      // tslint:disable-next-line: no-unused-expression
      callback && callback();
    } else {
      document.addEventListener('JSBridgeReady', callback, false);
    }
  }

  /** Redirect to thank you page after succuessfull payment on cards */
  goToThankYouPage(order) {
    this.zone.run(() => {
      this.util.router.navigate(['/new/thankyou'], { queryParams: this.vars.tyParams }).then(() => {
        this.dialog.closeAll();
        this.sheet.dismiss();
      });
      this.storage.deleteFromSession(order.order_id);
    });
  }

  eventForInitiatePayment(position?, fundraiser?, cart?, data?) {
    return new Promise((resolve, reject) => {
      try {
        const claverTapProperty = { 'Position': 11, 'Cart ID': cart?.id, 'Recurring': cart?.recurring ? true : false };
        if (this.vars?.fundraiser) {
          claverTapProperty['Custom Tag'] = this.vars.fundraiser?.custom_tag;
        }
        if (cart?.recurring) {
          claverTapProperty['SIP Acquisition page name'] = this.util.toTitleCase(data?.only_cause_list) + ' SIP';
        }
        if (this.vars.selected_insurance) {
          claverTapProperty['Plan Type'] = this.vars.selected_insurance.plan_text;
          claverTapProperty['Plan Validity'] = this.util.toTitleCase(this.vars.selected_insurance.frequency);
        }
        this.events.claverTapPush('Contribution Initiated', claverTapProperty);
        const dataLayer = {
          'event': 'Contribution Initiated',
          'event_category': 'Contribution Initiated',
          'event_action': 'click',
          'event_label': fundraiser?.title,
          'Campaign_custom_tag': fundraiser?.custom_tag,
          'Campaign_cause_id': fundraiser?.cause_id,
          'Campaign_id': Number(fundraiser?.id),
          'Campaign_Type': this.util.getCampaignTypeFromId(fundraiser?.parent_cause_id),
          'position': position || 11,
          sip: this.vars?.gtmPageData ? this.vars?.gtmPageData?.sip : false
        };
        this.events.gtmPush(dataLayer);
        // this.events.fbPixelPush('InitiateCheckout', {});
        const systemEvent = {
          eventName: 'Contribution Initiated',
          event_type: 'campaign',
          page_name: this.vars?.pageName || '',
          info_1: this.vars.event_info.info_1 || 'position',
          info_2: this.vars.event_info.info_2 || position || 11,
          info_3: (this.vars.event_info.info_3 || this.vars.campaignType) + `${this.vars.system_event.order_created.info_3}`,
          info_4: this.vars.event_info.info_4 || this.cookie.getCookie('varAB') || ''
        };
        if (fundraiser?.id) {
          systemEvent['event_type_id'] = fundraiser?.id;
        }
        if (data?.sip_cause && typeof data?.sip_cause === 'object') {
          systemEvent['info_1'] = this.util.arrayTostring(data?.only_cause_list, ',');
        }
        this.events.sendSystemEvent(systemEvent).subscribe(_ => _);
        resolve(true);
      } catch (error) {
        reject(false);
      }
    });
  }

  eventAfterCreatingCart(fundraiser: IFundraiser, cart: ICart, data) {
    return new Promise((resolve, reject) => {
      try {
        /**System event push */
        const systemEventCartCreate = {
          eventName: 'Contribution Cart',
          event_type: 'campaign',
          page_name: this.vars.pageName || '',
          info_1: this.vars.event_info.info_1 || '',
          info_2: cart.id,
          info_3: (this.vars.event_info.info_3 || this.vars.campaignType) + `${this.vars.system_event.order_created.info_3}`,
          info_4: this.vars.event_info.info_4 || this.cookie.getCookie('varAB') || '',
        };
        if (fundraiser?.id) {
          systemEventCartCreate['event_type_id'] = fundraiser?.id;
        }
        if (data?.sip_cause && typeof data?.sip_cause === 'object') {
          systemEventCartCreate['info_1'] = this.util.arrayTostring(data?.only_cause_list, ',');
        }
        if (this.slabs) {
          systemEventCartCreate.info_1 += `, ${JSON.stringify(this.slabs)}/${cart.donated_amount}`;
        }
        this.events.sendSystemEvent(systemEventCartCreate).subscribe(_ => _);
        // Criteo Basket/Cart dataLayer of GTM push
        const dataLayerCartCreate = {
          event: 'crto_basketpage',
          currency: cart.currency,
          crto: {
            email: cart.donor_email, // can be empty string if email not known
            products: [{
              id: fundraiser.id || '',
              price: cart.donated_amount,
              quantity: '1'
            }] // add new object for each product added to cart
          }
        };
        this.events.gtmPush(dataLayerCartCreate);
        /**Claver tap push */
        const claverTapProperty = {
          'id': fundraiser.id,
          'Step': 1,
          'Name': cart.donor_name,
          'Email': cart.donor_email,
          'NC Email ID': cart.donor_email,
          'Type_AB': 'typeAB',
          'Custom Tag': fundraiser.custom_tag,
          'Parent Cause Id': fundraiser.parent_cause_id,
          'Page Name': this.vars.campaignType || '',
          'iso_currency': cart.currency,
          'amount': cart.donated_amount - (cart.tip_amount || 0),
          'TipAmount': cart.tip_amount,
          'Cart ID': cart.id,
          'Recurring': cart.recurring ? true : false
        };
        if (cart.reward_id) {
          claverTapProperty['Amount'] = cart.donated_amount_inr;
          claverTapProperty['Reward'] = cart.reward_id;
        }
        if (cart.recurring) {
          claverTapProperty['SIP Acquisition page name'] = this.util.toTitleCase(data?.only_cause_list || 'medical') + ' SIP';
        }

        this.events.claverTapPush('New Contribution', claverTapProperty);
        const campaignType = `${this.util.getCampaignTypeFromId(fundraiser.parent_cause_id)}`;
        // Checkout/Cart create gtm event
        const gtmCheckout = {
          'event': 'addToCart',
          'sip': cart.recurring,
          'value': cart.donated_amount_inr,
          'currency': cart.currency,
          'ecommerce': {
            'add': {
              'products': [{
                'name': `${fundraiser.custom_tag}`, // Campaign Name
                'id': `${fundraiser.id}`, // Campaign ID.
                'category': campaignType,  // Campaign Type
                'variant': `${fundraiser.cause_id}`,
                'price': cart.donated_amount_inr,
                'quantity': 1,
                'dimension5': '', // cd_Fundraiser Attributes,
                'dimension6': cart.currency,
                'dimension7': `${fundraiser.cause_id}`, // cd_Campaign Cause ID
                'dimension8': `${fundraiser.custom_tag}`, // cd_Campaign Custom Tag
                'dimension9': `${fundraiser.id}`, // cd_Campaign ID
                'dimension10': campaignType, // cd_Campaign Type
                'dimension15': cart.donated_amount_inr, // cd_Donation Amount
                'dimension16': this.vars.selectTipPercentage || '', // cd_Tip Percentage
                'dimension17': cart.tip_amount_inr, // cd_Tip Amount,
              }]
            }
          }
        };
        this.events.gtmPush(gtmCheckout);
        resolve(true);
      } catch (error) {
        reject(false);
      }
    });
  }

  eventAfterCreatingOrder(order: IOrder, data) {
    // Returning promise because page was redirecting to send order before system event completes
    return new Promise((resolve, reject) => {
      try {
        const claverTap = {
          'id': order.campaign_id,
          'Step': 3,
          'Name': data.cart.donor_name,
          'Email': data.cart.donor_email,
          'Amount': order.donated_amount_local,
          'Payment Mode': order.payment_mode,
          'Type_AB': 'typeAB',
          'Page Name': this.vars.campaignType || '',
          'TipAmount': order.tip_amount_local,
          'Iso Currency': order.iso_currency,
          'Reward': data.reward_id || '',
          'Cart ID': data.cart.id,
          'Recurring': data.cart.recurring ? true : false
        };
        if (this.vars.fundraiser) {
          claverTap['Custom Tag'] = this.vars.fundraiser.custom_tag;
          claverTap['Parent Cause Id'] = this.vars.fundraiser.parent_cause_id;
        }
        if (data.cart.recurring) {
          claverTap['SIP Acquisition page name'] = this.util.toTitleCase(data?.only_cause_list || 'medical') + ' SIP';
        }

        this.events.claverTapPush('New Contribution Step 3', claverTap);
        this.gtmEventForOrder(order, 2, data.fundraiser, data.sip);
        const systemEvent = {
          'eventName': 'order_created',
          'event_type': 'campaign',
          'event_type_id': order.campaign_id,
          'page_name': this.vars.pageName || '',
          'info_1': this.vars.event_info.info_1 || '',
          'info_2': order.id,
          'info_3': (this.vars.event_info.info_3 || this.vars.campaignType) + `${this.vars.system_event.order_created.info_3}`,
          'info_4': this.vars.event_info.info_4 || ''
        };
        if (data?.cart?.recurring) {
          systemEvent['info_4'] = [1, 2].includes(this.util.checkForActiveSip(data?.user?.listsubscriptions)) ? 'existing' : 'fresh';
        } else {
          systemEvent['info_4'] = data?.user?.aggdonationall?.funds >= 1 ? 'repeat' : 'fresh';
        }
        if (data?.sip_cause) {
          systemEvent.info_3 += `; ${typeof data?.sip_cause === 'object' ? this.util.arrayTostring(data?.only_cause_list, ',') : data?.sip_cause};`;
        }
        this.events.sendSystemEvent(systemEvent).subscribe((next) => resolve(true), (err) => resolve(err));
      } catch (error) {
        reject(false);
      }
    });
  }

  gtmEventForOrder(order, step, fundraiser, sip?) {
    const gtmCheckout = {
      'event': 'checkout',
      'sip': sip || false,
      'value': order.donated_amount,
      'currency': order.iso_currency,
      'ecommerce': {
        'checkout': {
          'actionField': { 'step': step, 'option': order.payment_mode },
          'products': [{
            'name': fundraiser.custom_tag, // Campaign Name
            'id': fundraiser.id, // Campaign ID.
            'category': this.util.getCampaignTypeFromId(fundraiser.parent_cause_id),  // Campaign Type
            'variant': fundraiser.cause_id,
            'price': order.donated_amount,
            'quantity': 1,
            'dimension5': '', // cd_Fundraiser Attributes
            'dimension6': order.iso_currency,
            'dimension7': fundraiser.cause_id, // cd_Campaign Cause ID
            'dimension8': fundraiser.custom_tag, // cd_Campaign Custom Tag
            'dimension9': fundraiser.id, // cd_Campaign ID
            'dimension10': this.util.getCampaignTypeFromId(fundraiser.parent_cause_id), // cd_Campaign Type
            'dimension15': order.donated_amount, // cd_Donation Amount
            'dimension16': this.vars.selectTipPercentage || '', // cd_Tip Percentage
            'dimension17': order.tip_amount_inr // cd_Tip Amount
          }]
        }
      }
    };
    this.events.gtmPush(gtmCheckout);
  }

  /** Get the Min sip amount for diff currencies */
  getMinSipAmount(currency: string) {
    const currencies = this.vars.min_sip_amounts;
    currencies.INR = this.vars.typSipAmountDefaultINR;
    if (currency) {
      currency = currency?.toUpperCase();
      return {
        text: this.util.formatAmount(currencies[currency.toUpperCase()], currency),
        amount: currencies[currency],
        currency_symbol: Currency.find((item) => item.currency === currency.toUpperCase()).symbol
      };
    }
  }

  donorTypeEvent(fundraiser, cart, user) {
    const payload = {
      eventName: user?.aggdonationall?.funds >= 1 ? 'cart_created_repeat_user' : 'cart_created_fresh_user',
      event_type: 'campaign',
      page_name: this.vars.pageName || '',
      info_2: cart.id,
      info_3: this.vars.campaignType,
      info_4: this.cookie.getCookie('varAB') || '',
    };
    if (fundraiser?.id) {
      payload['event_type_id'] = fundraiser?.id;
    }
    this.events.sendSystemEvent(payload).subscribe();
  }

  preUsedOptionEvent(fundraiser, cart, option) {
    const payload = {
      eventName: 'saved_payment_used',
      event_type: 'campaign',
      page_name: this.vars.pageName || '',
      info_1: option,
      info_2: cart.id,
      info_3: this.vars.campaignType,
      info_4: this.cookie.getCookie('varAB') || '',
    };
    if (fundraiser?.id) {
      payload['event_type_id'] = fundraiser?.id;
    }
    this.events.sendSystemEvent(payload).subscribe();
  }

  totalSip(data: ISipCause[], config?: IPaymentSipConfig): { total_amount?: number, only_cause_list?: string[], sip_count?: number, is_valid?: boolean } {
    let total = 0;
    let is_valid = true;
    const cause_list: string[] = [];
    if (typeof data === 'string') {
      data = [{ sip_cause: data, amount: 0 }];
    }
    try {
      data.forEach(element => {
        if (element?.sip_cause) {
          total += +element?.amount || 0;
          cause_list.push(element?.sip_cause);
        }
        if (config?.paymentDetails && config?.paymentDetails?.min_donation > +element.amount &&
          (!config?.user?.listsubscriptions?.[element?.sip_cause] || config?.user?.listsubscriptions?.[element?.sip_cause]?.amount > +element.amount)) {
          is_valid = false;
        }
      });
    } catch (error) { }
    if (cause_list.length === 0 && config.currency) {
      total = this.getMinSipAmount(config.currency).amount;
      cause_list.push(this.vars.default_sip_cause);
    }
    if (config?.sip_wallet) {
      total = total * config?.wallet_tenure || 1;
    }
    this.paymentConfig.next({ cart_amount: total });
    return { total_amount: total, only_cause_list: cause_list, sip_count: cause_list.length, is_valid: is_valid };
  }

}

import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DonationService {

  constructor(
    @Inject(DOCUMENT) private document: any
  ) { }

  donateNow(inputs) {
    const form = this.document.createElement('form');
    form.setAttribute('medthod', 'post');
    form.setAttribute('action', environment.APP.API_URLS.CONTRIBUTE_NOW);
    for (const input of inputs) {
      const i = this.document.createElement('input');
      i.setAttribute('type', 'hidden');
      i.setAttribute('name', input.name);
      i.setAttribute('value', input.value);
      form.appendChild(i);
    }
    this.document.body.appendChild(form).submit();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService, EventsService, ScriptLoaderService, SignupService, StorageService, VariablesService } from '../../shared/services';

declare const google;

@Component({
  selector: 'app-google-onetap-login',
  templateUrl: './google-onetap-login.component.html',
  styleUrls: ['./google-onetap-login.component.scss']
})
export class GoogleOnetapLoginComponent implements OnInit {

  @ViewChild('userinfoRef', { static: true }) public userinfoRef;

  constructor(
    private scriptLoader: ScriptLoaderService,
    public dialoge: MatDialog,
    private auth: AuthService,
    private signupService: SignupService,
    public storageService: StorageService,
    public router: Router,
    public events: EventsService,
    public vars: VariablesService
  ) { }

  ngOnInit(): void {
    const user = this.storageService.get('userdata');
    if (typeof document !== 'undefined' && !user) {
      this.loadGoogleScript();
    }
  }

  async loadGoogleScript() {
    try {
      await this.scriptLoader.load('google_login');
      google.accounts.id.initialize({
        client_id: environment.google_client_id_one_tap,
        callback: this.handleCredentialRes.bind(this), // Whatever function you want to trigger...
        auto_select: true,
        cancel_on_tap_outside: false,
        prompt_parent_id: 'g_id_onload'
      });
      google.accounts.id.prompt((notification) => {
        // console.log('Google prompt event triggered...');
        if (notification.getDismissedReason() === 'credential_returned') {
          // console.log('Welcome back!');
          // redirect user as per requirement
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleCredentialRes(response) {
    // console.log(response);
    // need to call auth api here to login user in system
    this.auth.loginSocail(response.credential, 'LOGIN_GOOGLE_ONETAP').subscribe((res: any) => {
      this.vars.loginType = res.data.user_exists ? 'signin' : 'signup';
      this.vars.loginMethod = 'google_onetap';
      this.signupService.afterLogin(res , 'gonetap');
      this.events.sendSystemEvent({
        eventName: 'google_one_tap_login',
        page_name: this.vars.pageName,
      }).subscribe(_ => _);
    });
  }

}

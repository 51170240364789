import { AuthService } from './../../services/auth/auth.service';
import { UtilService } from './../../services/util/util.service';
import { environment } from './../../../../environments/environment';
import { StorageService } from '../../services/storage/storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { VariablesService } from '../../services/variables/variables.service';

@Injectable({
  providedIn: 'root'
})
export class SipGuard implements CanActivate {

  constructor(
    public router: Router,
    public storageService: StorageService,
    public utilService: UtilService,
    public authService: AuthService,
    private variablesService: VariablesService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkSip(route, state);
  }

  checkSip(route, state): any {
    const query_param = { ...this.utilService.actRoute.snapshot.queryParams };
    const paramsString = this.utilService.objToUrlString(query_param);

    return new Promise((resolve, reject) => {
      try {
        if (route?.queryParams?.utm_source?.match('internal_impact_calling')) {
          resolve(true);
          return;
        }
        const userdata = this.storageService.get('userdata');
        const user = this.storageService.get('user');
        const status_flag = this.utilService.checkForActiveSip(userdata?.listsubscriptions);
        if ([1, 2, 4, 5].includes(status_flag)) {
          let url = environment.sipretention_domain;
          if (paramsString && !(this.utilService.getLengthToMultiply(query_param?.sip_cause) > 1)) {
            url = url + '?' + paramsString;
          }
          if (user && user.user && user.user.isLoggedIn) {
            if (!this.storageService.getFromSession('sipUserRedirect')) {
              this.storageService.addSessionData('sipUserRedirect', true);
            }
            if (route.queryParams.utm_source === 'external_ct' && this.utilService.isMobile()) {
              this.router.navigate(['/new/download-page'], { queryParams: { 'redirect_uri': url } });
              reject(false);
            }
            this.utilService.nativeNavigation(url);
          } else {
            if (route.queryParams.utm_source === 'external_ct' && this.utilService.isMobile()) {
              this.router.navigate(['/new/download-page'], { queryParams: { 'redirect_uri': url } });
              reject(false);
            }
            const qPrams = Object.assign({ redirect_uri: state.url.split('?')[0] }, state.root.queryParams);
            this.utilService.redirectToLogin();
          }
        } else {
          resolve(true);
        }
      } catch (error) {
        reject(false);
      }
    });
  }
}

import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { environment } from '../../environments/environment';

// if (environment.production) {
//   Bugsnag.start(environment.bugsnag);
// }

// create a factory which will return the Bugsnag error handler
// export function errorHandlerFactory() {
//   return environment.production ? new BugsnagErrorHandler() : '';
// }

@NgModule({
  imports: [
    CommonModule
  ],
  // providers: [
  //   environment.production ? { provide: ErrorHandler, useFactory: errorHandlerFactory } : []
  // ],
})

export class ErrorHandlerModule { }

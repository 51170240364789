import { Directive, Input } from '@angular/core';

@Directive({
  selector: 'img[default]',
  host: {
    '(error)': 'updateUrl()',
    '[src]': 'src'
  }
})
export class ImgLoaderDirective {

  @Input() src = '';
  @Input() default: string;

  constructor() { }

  updateUrl(): void {
    this.src = this.default;
  }

}

import { SacPurposeDialogComponent } from '../../../common/sac-purpose/components/sac-purpose-dialog/sac-purpose-dialog.component';
import { EventsService } from '../../services/events/events.service';

import { VariablesService } from '../../services/variables/variables.service';
import { UtilService } from '../../services/util/util.service';
import { HomepageService } from '../../../homepage/services/homepage.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { StorageService } from '../../services/storage/storage.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-common-draft-fundraiser',
  templateUrl: './common-draft-fundraiser.component.html',
  styleUrls: ['./common-draft-fundraiser.component.scss']
})
export class CommonDraftFundraiserComponent implements OnInit {

  isMobile: any;
  draftUrl: string;
  startFrType: string;
  draftImg = 'assets/images/homepage/draft-bg.png';
  parentCauses: any;
  userData;
  hideDiscardButton = true;
  type = '';
  loadTalktousPopup = false;
  startDate;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommonDraftFundraiserComponent>,
    public homepageService: HomepageService,
    public storageService: StorageService,
    public utilService: UtilService,
    private variablesService: VariablesService,
    private eventsService: EventsService,
    private router: Router,
    private eventService: EventsService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.parentCauses = this.variablesService.parentCauses;
    this.isMobile = this.utilService.isMobile();
    this.setDraftLeaderboardImg();
    switch (this.data.status_flag) {
      case 8:
        this.type = 'underReview';
        break;
      case 7:
        this.type = 'draft';
        break;
      case 1:
        this.type = 'approved';
        break;
    }
    this.userData = this.utilService.getUserData();
    if (typeof window !== 'undefined') {
      if (window.location.pathname.includes('/new/crowdfunding')) {
        this.hideDiscardButton = false;
      }
    }
    this.dialogShownTracking();
    this.startDate = this.getStartDate(this.data?.start_date);
  }

  private setDraftLeaderboardImg() {
    if (this.data.media && this.data.media.length) {
      for (const item of this.data.media) {
        if (item.display_type_1 === 'leaderboard') {
          this.draftImg = item.cdn_path;
        }
      }
    }
  }

  goToDraft() {
    if (typeof document !== 'undefined') {
      const payload = {
        eventName: 'start_a_campaign_initiated',
        event_type: 'campaign',
        page_name: this.variablesService.pageName || 'home',
        referrer_page: document.referrer,
        info_1: 'type',
        info_2: 'draft-popup'
      };
      this.storageService.checkFromSession('sac-initiated', payload);
      this.eventService.sendSystemEvent(payload).subscribe(() => { });
    }
    this.startFrType = (this.data.parent_cause_id === 20 && this.data.cause_id === 59) ? 'medical' : 'non-medical';
    this.router.navigate(
      [this.variablesService.startAFrUrls[this.startFrType]],
      { queryParams: { did: this.data.id }, queryParamsHandling: 'merge' }
    );
    this.dialogRef.close();
  }

  discardDraft() {
    this.dialogRef.close();
    const token = this.utilService.getUser()?.token;
    this.router.navigate([], {
      queryParams: { did: null },
      queryParamsHandling: 'merge'
    });
    // this.homepageService.discardDraft(token)
    //   .subscribe(arg => {
    //     this.openChangePurpose();
    //   });
  }

  openChangePurpose() {
    const dialogRef = this.dialog.open(SacPurposeDialogComponent, {
      panelClass: 'sac-purpose-changer',
      data: {
        frType: 'medical',
        showWarningText: false
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data && result.data !== 'medical') {
        this.router.navigate(
          [`${this.variablesService.startAFrUrls['non-medical']}/basic`],
          { queryParams: { type: result.data }, queryParamsHandling: 'merge' }
        );
      }
    });
  }

  getStartDate(date) {
    const arr = date.split(/[- :]/);
    const dt = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`;
  }

  /*Redirect to contact us */
  redirectToContactUs() {
    this.loadTalktousPopup = true;
    const payload = {
      eventName: 'query_contact_us',
      event_type: 'campaign',
      info_1: 'type',
      info_2: 1,
      info_3: 'duplicate_campaign_popup',
      page_name: this.variablesService.pageName || '',
    };
    this.eventsService.sendSystemEvent(payload).subscribe();
    if (typeof window !== 'undefined') {
      window.location.href = 'https://www.ketto.org/support/contact-us.php';
    }
  }

  openEditFr() {
    const payload = {
      eventName: 'edit_fundraiser',
      event_type: 'campaign',
      info_1: 'type',
      info_2: 1,
      info_3: 'duplicate_campaign_popup',
      page_name: this.variablesService.pageName || '',
    };
    try {
      this.eventsService.sendSystemEvent(payload).subscribe();
    } catch (error) {
      console.log(error);
    }
    const url = environment.manage_domain + '/manage/' + this.data.id + '/edit';
    this.utilService.nativeNavigation(url);
  }

  dialogShownTracking() {
    const payload = {
      eventName: 'pageView',
      event_type: 'campaign',
      info_1: 'type',
      info_2: 1,
      info_3: this.data.status_flag === 7 ? 'draft_popup' : 'duplicate_campaign_popup',
      page_name: this.variablesService.pageName || '',
    };
    this.eventsService.onLoadSystemEvent(payload);
  }

  onUnderReviewClose() {
    this.dialog.closeAll();
    if (typeof window !== 'undefined') {
      if (this.type === 'underReview' && window.location.pathname.includes('/new/crowdfunding')) {
        this.utilService.navigate('/');
      }
    }
  }

  createNewCampaignFromPopup() {
    this.dialog.closeAll();
    switch (this.variablesService.campaignPurpose) {
      case 'ngo':
        this.router.navigate(['new/crowdfunding/non-medical/basic'], { queryParams: { type: 'ngo', did: null }, queryParamsHandling: 'merge'});
        break;
      case 'other':
        this.router.navigate(['new/crowdfunding/non-medical/basic'], { queryParams: { type: 'other', did: null }, queryParamsHandling: 'merge' });
        break;
      case 'medical':
        this.router.navigate(['new/crowdfunding/medical/basic'], { queryParams: {did: null}, queryParamsHandling: 'merge' } );
        break;
    }
  }

}

export const LANG = {
  English: {
    hi: 'Hi',
    label: 'Select the purpose of your Fundraiser',
    next: 'Next',
    warning: 'By clicking next you will discard any changes made to this page'
  },
  Hindi: {
    hi: 'नमस्ते',
    label: 'अपने फंडरेसर के उद्देश्य का चयन करें',
    next: 'आगे',
    warning: 'आगे पर क्लिक करके इस पृष्ठ पर किए गए किसी भी परिवर्तन को आप रद्द कर देंगे'
  },
  Telugu: {
    hi: 'నమస్కారం',
    label: 'మీ ఫండ్రైజెర్ యొక్క ప్రయోజనాన్ని ఎంచుకోండి',
    next: 'తరువాత',
    warning: '"తరువాత" క్లిక్ చేయడం వల్ల మీరు ఈ పేజీలో చేసిన ఏవైనా మార్పులను విస్మరిస్తారు'
  },
  Tamil: {
    hi: 'ஹாய்',
    label: 'உங்கள் நிதித் திரட்டலுக்கான நோக்கத்தை தேர்ந்தெடுக்கவும்',
    next: 'அடுத்து',
    warning: 'அடுத்து கிளிக் செய்வதன் மூலம் இந்தப் பக்கத்தில் செய்யப்பட்ட மாற்றங்களை நிராகரிப்பீர்கள்'
  },
};

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

declare const Juspay;
declare const $;
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  public APIURL = environment.APP.BASE_URL;
  public CORE_API = environment.APP.CORE_API;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  public httpOptionsHideError = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: {
      showError: 'false'
    }
  };

  public juspayToken = new Subject<any>();

  constructor(
    public http: HttpClient,
  ) { }
  /**
   * Get the details for contribute form
   * @param campaignId
   */
  getContributeDetails(campaignId, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.CONTRIBUTE_DETAILS_PAYMENT_FORM(campaignId);
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**
   * Get the payment options available
   */
  getPaymentOptions(currency, params?) {
    let qPram = `?currency=${currency}`;
    if (params) {
      qPram = qPram.concat(`${params}`);
    }
    const url = this.APIURL + environment.APP.API_URLS.GET_PAYMENT_OPTIONS;
    return this.http.get(url + qPram, this.httpOptions);
  }
  /**
   * Send Amount to get the details
   * of the payments/order
   * @param data Data required to get oder details
   * @param campaignId Campaign ID
   */
  sendAmount(data, campaignId) {
    const url = this.APIURL + environment.APP.API_URLS.POST_AMOUNT_DETAILS(campaignId);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Place the order for the selected
   * by the user
   * @param data Data required to place order
   * @param campaignId Campaign ID
   */
  placeOrder(data, campaignId) {
    const url = this.APIURL + environment.APP.API_URLS.PLACE_ORDER(campaignId);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**Check the payment response after redirection from gateway
   * and get status of the order (successfull/failed)
   * @param data Response after payment
   */
  getOrderStatusAfterPayment(data) {
    const url = this.APIURL + environment.APP.API_URLS.GATEWAY_RESPONSE(data.oi);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Complete the order
   * @param data Data required to send order
   */
  sendOrder(data) {
    const url = this.APIURL + environment.APP.API_URLS.SEND_ORDER;
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**Juspay token generate to coomplte
   * the card payment using juspay
   * @param data All the card details to generate token
   */
  juspayTokenGenerate(data) {
    const url = environment.juspay + 'card/tokenize?' + data;
    return this.http.jsonp(url, 'callback').pipe(
      map((res: any) => {
        return res.results.map(item => {
          console.log('asdasdasdasdasd', item);
          return item;
        });
      }),
      tap(res => console.log('fetched sellers')),
    );
  }
  /**Submit to the payment gateway */
  submitToPaymentGateway(res, gateway?, mode?) {
    const gatewayRespnse = res; // assuming that `res` holds the data return by JusPay API
    const url = gatewayRespnse.url;
    const method = gatewayRespnse.method;

    const frm = document.createElement('form');
    frm.setAttribute('method', method);
    frm.setAttribute('action', url);
    frm.setAttribute('id', 'payment_form');
    frm.setAttribute('class', 'juspay_inline_form'); // Class for juspay payment gateway

    if (gatewayRespnse.params) {
      const params = gatewayRespnse.params;
      for (const key of Object.keys(params)) {
        const value = params[key];
        const field = document.createElement('input');
        field.setAttribute('type', 'hidden');
        field.setAttribute('name', key);
        field.setAttribute('class', key);
        field.setAttribute('value', value);
        frm.appendChild(field);
      }
    }
    document.body.appendChild(frm);
    if (gateway === 'juspay' && mode === 'CARD') {
      console.log('Juspay submit setup');
      this.juspayFormSubmit();
      $('#payment_form').submit();
    }
    // form is now ready
    frm.submit();
  }

  /**Juspay form submit */
  juspayFormSubmit() {
    Juspay.Setup({
      payment_form: '#payment_form',
      success_handler: function (status) {
        // redirect to success page
        console.log('success');
        console.log('status', status);
      },
      error_handler: function (error_code, error_message, bank_error_code, bank_error_message, gateway_id) {
        // redirect to failure page
        console.log('failure');
        console.log('error_message', error_message);
        console.log('error_code', error_code);
        console.log('bank_error_code', bank_error_code);
        console.log('bank_error_message', bank_error_message);
        console.log('gateway_id', gateway_id);
      }
    });
  }

  /**
   * Get premium amount of the insurance
   * @param data object required
   */
  getInsurancePremium(data) {
    const url = this.APIURL + environment.APP.API_URLS.GET_INSURANCE_PREMIUM;
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Add insurance
   * @param data object required
   */
  addInsurance(data) {
    const url = this.APIURL + environment.APP.API_URLS.ADD_INSURANCE;
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * AFter payment send the confirmation to db
   * to check if order is failed or succesfull
   * @param data object required
   */
  checkPaymentInsurance(data) {
    const url = this.APIURL + environment.APP.API_URLS.UPDATE_PAYMENT_INSURANCE;
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Payment insurance
   * @param data object required
   * @param id insurance id
   */
  initiatePayment(data) {
    const url = this.APIURL + environment.APP.API_URLS.PAYMENT_INSURANCE;
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**Get insurance info
   * @param id insurance Id
   */
  getInsuranceInfo(id: string) {
    const url = this.APIURL + environment.APP.API_URLS.GET_INSURANCE_INFO(id);
    return this.http.get(url, this.httpOptions);
  }
  /**
   * Check if the UPI id is valid or not
   * @param data VPA ID in object
   */
  vpaIdValidation(data) {
    const url = this.APIURL + environment.APP.API_URLS.VPA_VALIDATION;
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Check the txn status of the vpa order
   * @param data Order Id in object
   */
  vpaTxnStatus(data) {
    const url = this.APIURL + environment.APP.API_URLS.VPA_TXN_STATUS;
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }

  /**check if paytm apis are ready */
  isPaytmReady(callback) {
    if ((<any>window).JSBridge && callback) {
      callback();
    } else {
      document.addEventListener('JSBridgeReady', callback, false);
    }
  }
  /**Paytm auth login */
  paytmAuthFetch() {
    const clientId = environment.production ? 'merchant-ketto-prod' : 'merchant-ketto-uat';
    return new Promise((resolve, reject) => {
      try {
        this.isPaytmReady(() => {
          ((<any>window).JSBridge).call('paytmFetchAuthCode', { clientId: clientId }, (result) => {
            if (result.hasOwnProperty('error')) {
              reject(result.error);
            } else {
              const qparam = `?code=${result.data.authId}`;
              this.paytmLogin(qparam).subscribe((res) => resolve(res), (er) => reject(er));
            }
          });
        });
      } catch (error) {
        reject(false);
      }
    });
  }
  /**Paytm login wrapper */
  paytmLogin(qparam) {
    const url = this.APIURL + environment.APP.API_URLS.PAYTM_LOGIN;
    return this.http.get(url + qparam, this.httpOptions);
  }

  /**Get subscriptions */
  getSubscriptions(query?) {
    const url = this.APIURL + environment.APP.API_URLS.GET_SUBSCRIPTIONS + query;
    return this.http.get(url, this.httpOptions);
  }

  /**Get User subscriptions */
  getUserSubscriptions(qparam?) {
    const url = this.APIURL + environment.APP.API_URLS.GET_LATEST_SUBS + (qparam || '');
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: { showError: 'false' }
    };
    return this.http.get(url, options);
  }

  simplEligibilityCheck(data) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: { showError: 'false' }
    };
    const url = this.APIURL + environment.APP.API_URLS.SIMPL_ELIGIBILITY_CHECK;
    return this.http.post(url, JSON.stringify(data), options);
  }

  sendCriticalIllness(entity_id, data) {
    const url = this.APIURL + environment.APP.API_URLS.ADD_CRITICAL_ILLNESS(entity_id);
    return this.http.put(url, JSON.stringify(data), this.httpOptions);
  }

  addCriticalIllness(entity_id, order_id, selectedIllness: { person: number, cover: string, illnessAmount: number }) {
    const payload = {
      info_1: JSON.stringify({
        person: selectedIllness?.person,
        cover: selectedIllness?.cover,
        add_on_price: selectedIllness?.illnessAmount
      }),
      info_2: order_id,
      info_type: 'critical_illness'
    };
    this.sendCriticalIllness(entity_id, payload).subscribe({
      next: (resp) => {
      }
    });
  }
}

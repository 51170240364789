<!-- Top navigation -->
<div *ngIf="config?.showHeader">
  <app-module-loader [moduleId]="'common-header'" [selector]="'app-common-header'" [load]="config?.showHeader" [inputs]="{config: config}"></app-module-loader>
</div>

<div *ngIf="config?.showHeaderV2">
<app-component-loader [load]="true" selector="app-header" [inputs]="{config: config}" />
</div>


<!-- Main view/routes wrapper-->
<router-outlet></router-outlet>

<div *ngIf="config?.showFooterSEO">
<app-component-loader [load]="true" selector="app-footer-seo" [inputs]="{config: config}" />
</div>

<div *ngIf="config?.showFooter">
<app-component-loader [load]="true" selector="app-footer" [inputs]="{config: config}" />
</div>

<div *ngIf="config?.showAbandonedCart">
<app-component-loader [load]="true" selector="app-abandoned-cart" />
</div>

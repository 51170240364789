import { BehaviorSubject, Subject } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { StorageService } from './../storage/storage.service';
import { Injectable, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TickrService implements OnInit {

  private tickrStr: any;
  /**
   * Tickr arr stored in session for all the campaigns tickr has appeared
   */
  public tickrArr: any = [];
  // public getRecentDonations = 5;
  /**
   * How many tickrs will appear
   */
  public reduceDonorsBy = 3;
  public willTickrShowObs = new BehaviorSubject<any>(null);
  public tickrIsShown = new BehaviorSubject<any>(false);
  public tickrClickObs = new Subject();
  public lastTickrShownObs = new Subject();
  public stopTickr = false;
  public tickrShownCount = 0;
  public tickrData = [];

  /**
   * Tickr intervals for various devices
   */
  public Mobile = {
    interval: [1000, 15000, 27000, 40000],
    hideAfter: 4000
  };
  public Desktop = {
    interval: [4500, 14000, 26000, 40000],
    hideAfter: 4500
  };

  public httpOptions: object = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  public baseUrl: string = environment.APP.BASE_URL;
  apiUrl = environment.APP.API_URLS;

  constructor(
    private storageService: StorageService,
    public http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.tickrStr = this.storageService.getFromSession('tickr');
    this.tickrArr = this.tickrStr ? JSON.parse(this.tickrStr) : [];
  }

  addCampaignId(id: number) {
    this.tickrArr.push(id);
    this.storageService.addSessionData('tickr', JSON.stringify(this.tickrArr));
  }

  getTickrData(payload) {
    return this.http.get(this.baseUrl + this.apiUrl.GET_TICKR_DATA(payload), this.httpOptions);
  }

  resetTickr() {
    this.tickrShownCount = 0;
    this.tickrData = [];
    this.tickrIsShown.next(false);
  }
}

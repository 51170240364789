<h2 mat-dialog-title class="text-center">
  <div class="user-name">
    {{ lang[currentLanguage].hi }} {{ userdata?.fname }}!
  </div>
  <div>
    {{ lang[currentLanguage].label }}
  </div>
  <button class="close-btn" mat-dialog-close mat-icon-button>
    <i class="material-icons">close</i>
  </button>
</h2>
<mat-dialog-content>
  <app-sac-purpose [frType]="data.frType" (selectionChangeEvent)="purposeSelectionEvent($event)" [currentLanguage]="currentLanguage"></app-sac-purpose>
</mat-dialog-content>
<app-button type="button" [label]="lang[currentLanguage].next" suffixIcon='arrow_right_alt' btnClass="non-rounded-btn width-100" (click)="doAction()"></app-button>
<div class="bottom-text" *ngIf="data.showWarningText">{{ lang[currentLanguage].warning }}</div>

import { createNgModuleRef, Directive, Injector, Input, NgModuleRef, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { LazyModuleLoaderModel } from './lazy.model';

@Directive({
  selector: '[appLazyDirective]'
})
export class LazyDirective implements OnChanges {

  @Input() appLazyDirective!: LazyModuleLoaderModel;
  @Input() inputs;
  @Input() outputs;

  constructor(
    private container: ViewContainerRef,
    private injector: Injector
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.appLazyDirective?.currentValue) {
      return;
    }
    if (typeof this.appLazyDirective.loader === 'function') {
      this.loadModule();
    }
  }

  loadModule() {
    return (this.appLazyDirective.loader() as Promise<any>).then((module) => {
      const moduleName = Object.keys(module)[0];
      const lazymodule = module[moduleName];
      let moduleRef: NgModuleRef<any>;
      moduleRef = createNgModuleRef(lazymodule, this.injector);
      const component = moduleRef.instance.getComponent();
      this.container.clear();
      const { instance } = this.container.createComponent(component, { ngModuleRef: moduleRef });
      if (typeof this.inputs === 'object') {
        Object.keys(this.inputs).forEach(element => {
          instance[element] = this.inputs[element];
        });
      }
      if (typeof this.outputs === 'object') {
        Object.keys(this.outputs).forEach(element => {
          instance[element].subscribe((e) => {
            if (typeof this.outputs[element] === 'function') {
              this.outputs[element](e);
            }
          });
        });
      }
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModuleLoaderComponent } from './module-loader.component';
import { LazyDirective } from './directive/lazy-directive.directive';

@NgModule({
  declarations: [ModuleLoaderComponent, LazyDirective],
  exports: [ModuleLoaderComponent],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
})
export class ModuleLoaderModule { }

import { UtilService } from './../../../shared/services/util/util.service';
import { Component } from '@angular/core';

import { IMainLayoutConfig } from '../../../shared/models/main-layout.config';
import { VariablesService } from '../../../shared/services';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {

  public config: IMainLayoutConfig;
  constructor(
    public variablesService: VariablesService,
    public utilService: UtilService
  ) {
    this.variablesService.mainLayoutConfig.subscribe({
      next: res => {
        setTimeout(() => {
          this.config = res;
        }, 0);
      }
    });
  }

}

<app-google-onetap-login *ngIf="router?.url?.match('stories')"></app-google-onetap-login>
<!-- Router outlet -->
<div>
  <router-outlet></router-outlet>
</div>

<div *ngIf="loadingRouteConfig" class="content-wrapper">
  <div class="l-placeholder">
    <div class="l-animated-background"></div>
  </div>
</div>

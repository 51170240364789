import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UtilService } from '../util/util.service';
@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  public APIURL = environment.APP.BASE_URL;
  public CORE_API = environment.APP.CORE_API;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    public http: HttpClient,
    public util: UtilService
  ) { }
  /**
   * Get the details of successfully donations
   * of the user
   * @param qParam Query params
   */
  getBeneficiaries(qParam) {
    this.util.isToken = true;
    const url = this.APIURL + environment.APP.API_URLS.GET_USER_ORDERS;
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**
   * Get the user summery like funds, doantions
   * average donations
   * @param qParam Query params
   */
  getDonorSummary(qParam?) {
    this.util.isToken = true;
    const url = this.APIURL + environment.APP.API_URLS.GET_USER_SUMMARY;
    return this.http.get(url + qParam, this.httpOptions);
  }

  /**
   * Get the recurring contribute deatils
   * @param qParam Query params
   */
  getContributeDetails(qParam?) {
    this.util.isToken = true;
    const url = this.APIURL + environment.APP.API_URLS.GET_RECURRING_CONTRIBUTE_DETAILS;
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**Get the campaigns */
  getCampaigns(qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_CAMPAIGNS;
    return this.http.get(url + qParam, this.httpOptions);
  }

  getTickrData(params?) {
    const url = this.APIURL + environment.APP.API_URLS.PORTFOLIO_TICKR + `?country_code=${params?.country_code || ''}`;
    return this.http.get(url, this.httpOptions);
  }

  getSuccessStories(qParam) {
    const url = this.APIURL + environment.APP.API_URLS.SUCCESS_STORIES;
    return this.http.get(url + qParam, this.httpOptions);
  }

  getUserSubscription(id, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_USER_SUBSCRIPTION(id);
    return this.http.get(url + qParam, this.httpOptions);
  }

  increasePledge(id, data) {
    const url = this.APIURL + environment.APP.API_URLS.INCREASE_PLEDGE(id);
    return this.http.put(url, data, this.httpOptions);
  }

  redeemAmount(data) {
    const url = this.APIURL + environment.APP.API_URLS.AMOUNT_REDEEM;
    return this.http.post(url, data, this.httpOptions);
  }
}

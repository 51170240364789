import { Injectable } from '@angular/core';
import { FacebookService } from 'ngx-facebook';
import { environment } from '../../../../environments/environment';
import { ScriptLoaderService } from '../script-loader/script-loader.service';

/**Facebook variable */
declare const FB;

@Injectable({
  providedIn: 'root'
})
export class FbService {

  constructor(
    public fb: FacebookService,
    public scriptLoaderService: ScriptLoaderService
  ) { }

  getFacebookID() {
    const appIds = [
      '2371926249799384|0ed478f8f1b1c9ec5bf0afca3407e43e',
      '412730782638081|ae3e4947d3bef0ab1ecb3804246c1304',
      '2399457636740236|b6b0ac82abd2600e0c72b386dd61382c',
      '422205541932141|0b7a7683d8935f525c40134a5249f4b5',
      '2388821171404839|5cdc0155991ee0fb086c8ba42ffeb0e8',
      '1045930105596680|3fdc5d46a8d6e56dda75227d68482c5b',
      '637447243370103|a9220654ae8adbf1d99c90f296074c88',
      '1058901017639168|9f9e552a125e6945c07a2991c34bf606',
      '337663723602820|05e102ad2bd04e00dbbbcab86bbae92f',
      '2223471721302253|9c2d304a709435071407b85d6b07eab1',
      '817890751925466|07bf92f33f8dbd2c320b88fb04a01d72',
      '314732132540039|32f604bd5902d36b30ae4e8efe39228f',
      '363346480951851|77eb111843f44f7d259ce559b158483d',
      '350924695532240|d5250e09adcbd93b510bd026979f8192',
      '411478629648295|f87acc04f1b9c513ab4d9104331523e8'
    ];
    return appIds[Math.floor(Math.random() * appIds.length)];
  }
  /**Check if alreaddy loaded*/
  checkIfFBNotLoadedALready() {
    return typeof FB === 'undefined';
  }
  /**Load script of fb sdk*/
  loadFBScript() {
    if (this.checkIfFBNotLoadedALready()) {
      this.scriptLoaderService.load('facebooksdk').then(() => {
        this.initFacebook();
        // console.log('fb loaded', FB);
      });
    }
  }
  /** FB Script loader promise */
  scriptLoaderFB() {
    return new Promise(async (resolve, reject) => {
      try {
        if (typeof FB === 'undefined') {
          await this.scriptLoaderService.load('facebooksdk');
          await this.initFacebook();
          resolve(true);
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(false);
      }
    });
  }
  /**Init facebook */
  async initFacebook() {
    if (typeof window !== 'undefined') {
      return new Promise((resolve, reject) => {
        try {
          this.fb.init({
            appId: environment.facebook_id,
            xfbml: true,
            version: 'v3.2'
          });
          resolve(true);
        } catch (error) {
          reject(false);
        }
      });
    }
    return false;
  }
}

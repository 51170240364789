import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyDirective } from './lazy/lazy.directive';

@Component({
  selector: 'app-component-loader',
  standalone: true,
  imports: [CommonModule, LazyDirective],
  templateUrl: './component-loader.component.html',
  styleUrls: ['./component-loader.component.scss']
})
export class ComponentLoader implements OnChanges {

  @Input() load: boolean = false;
  @Input() selector: string = '';
  @Input() inputs: any;
  @Input() outputs: any;

  lazy_config: any;

  ngOnChanges(changes: SimpleChanges): void {
    if ((!changes?.['load']?.currentValue) || (changes?.['load']?.currentValue === changes?.['load']?.previousValue) || (changes?.['load']?.currentValue?.toString() === changes?.['load']?.previousValue?.toString())) {
      return;
    }
    this.loadLazyComponent();
  }

  loadLazyComponent() {
    if (!this.load) {
      return;
    }
    this.lazy_config = {
      component: this.getPath()
    }

  }

  getPath() {

    switch (true) {
      case this.selector === 'app-abandoned-cart':
        return () => import('../abandoned-cart/abandoned-cart.component');

      case this.selector === 'app-extra-product-details':
        return () => import('../extra-product-details/extra-product-details.component');

      case this.selector === 'app-extra-product-select':
        return () => import('../extra-product-select/extra-product-select.component');

      case this.selector === 'app-faq':
        return () => import('../faq/faq.component');

      case this.selector === 'app-footer':
        return () => import('../footer/footer.component');

      case this.selector === 'app-footer-seo':
        return () => import('../footer-seo/footer-seo.component');

      case this.selector === 'app-header':
        return () => import('../header/header.component');

      case this.selector === 'app-hf-mini-typ':
        return () => import('../hf-mini-typ/hf-mini-typ.component');

      case this.selector === 'app-how-it-works':
        return () => import('../how-it-works/how-it-works.component');

      case this.selector === 'app-multi-patient-dropdown':
        return () => import('./../multi-patient-dropdown/multi-patient-dropdown.component');

      case this.selector === 'testing':
        return () => import('./../testing/testing.component');

      case this.selector === 'app-otd-price-breakdown':
        return () => import('./../otd-price-breakdown/otd-price-breakdown.component');

      case this.selector === 'app-our-mission':
        return () => import('../our-mission/our-mission.component');

      case this.selector === 'app-tax-benefit-banner':
        return () => import('../tax-benefit-banner/tax-benefit-banner.component');

      case this.selector === 'app-thankyou-page-standard':
        return () => import('../thankyou-page-standard/thankyou-page-standard.component');

      case this.selector === 'app-why-us':
        return () => import('../why-us/why-us.component');

      default:
        return;
    }

  }

}

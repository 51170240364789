<div class="container-loading">
  <div class="heading">
    <span>Payment is under process...</span>
  </div>
  <div class="lds-spinner">
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
  </div>
  <p>
    <small>Please do not refresh or press back button on this page</small>
  </p>
</div>
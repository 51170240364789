import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../../services/storage/storage.service';
import { UtilService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public router: Router,
    public storageService: StorageService,
    public util: UtilService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state);
  }
  checkLogin(state: RouterStateSnapshot) {
    const data = this.storageService.get('userdata');
    if (data) {
      return true;
    } else {
      const qPrams = Object.assign({ redirect_uri: state.url.split('?')[0] }, state.root.queryParams);
      this.util.redirectToLogin();
      return false;
    }
  }
}

import { EventsService } from './../../../../shared/services/events/events.service';
import { StorageService } from './../../../../shared/services/storage/storage.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VariablesService } from './../../../../shared/services/variables/variables.service';
import { Component, OnInit, Inject } from '@angular/core';
import { LANG } from '../../objects/lang';

@Component({
  selector: 'app-sac-purpose-dialog',
  templateUrl: './sac-purpose-dialog.component.html',
  styleUrls: ['./sac-purpose-dialog.component.scss']
})
export class SacPurposeDialogComponent implements OnInit {

  public purpose;
  public userdata: any;
  public currentLanguage: any;
  public lang = LANG;

  constructor(
    public variablesService: VariablesService,
    public dialogRef: MatDialogRef<SacPurposeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storageService: StorageService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.userdata = this.storageService.get(`userdata`);
    this.currentLanguage = this.storageService.get('language') || 'English';
    this.purpose = this.data.frType;
  }

  doAction() {
    this.dialogRef.close({
      data: this.purpose
    });
    const sacPuposeSelected = this.storageService.getFromSession('sac-purpose-selected');
    const sacInitiatedPayload = this.storageService.getFromSession('sac-initiated');
    if (!sacPuposeSelected) {
      this.storageService.addSessionData('sac-purpose-selected', { 'purpose': this.purpose });
      const payload = {
        eventName: 'cause_selection',
        page_name: this.variablesService.pageName,
        info_1: sacInitiatedPayload.info_1 || '',
        info_2: sacInitiatedPayload.info_2 || '',
        info_4: this.purpose,
        event_type: 'campaign'
      };
      this.eventsService.systemEventPush(payload);
    }
  }

  purposeSelectionEvent(data) {
    this.purpose = data;
    this.variablesService.purposeSelected = true;
  }

}

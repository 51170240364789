import { Injectable } from '@angular/core';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import { UtilService } from '../util/util.service';
declare const lightGallery;
@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(
    private utilService: UtilService,
    private script: ScriptLoaderService
  ) { }

  /** Initiate the gallery function */
  async initGallery(elementId, config?) {
    if (this.utilService.isBrowser) {
      if (typeof lightGallery !== 'undefined') {
        lightGallery(this.utilService.doc.getElementById(elementId), config);
      } else {
        this.utilService.addCssToGlobal('https://cdnjs.cloudflare.com/ajax/libs/lightgallery-js/1.4.0/css/lightgallery.min.css');
        await this.script.load('lightgallery');
        await this.script.load('lg-zoom');
        await this.script.load('lg-hash');
        await this.script.load('lg-thumbnail');
        this.initGallery(elementId, config);
      }
    }
  }
}
